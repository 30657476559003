import {
  AnalyticsTypeEnum,
  AggregatedAnalytics as RawAggregatedAnalytics,
  GetRawAnalytics200ResponseInner as RawAnalyticsData,
  RawAnalyticsDataDeliveryTime,
  RawAnalyticsDataDeviceOrder,
  RawAnalyticsDataFirstMeasurement,
  RawAnalyticsDataNpsScore,
  RawAnalyticsDataSurveyResponse,
} from '@vetahealth/tuna-can-api'
import i18next from 'i18next'
import { orderBy } from 'lodash-es'
import { config } from './config'
import { GroupedAggregatedAnalytics, RawAnalytics } from './types'

function isDevicesOrdered(data: RawAnalyticsData): data is RawAnalyticsDataDeviceOrder {
  return 'orderType' in data
}

function isDeliveryTime(data: RawAnalyticsData): data is RawAnalyticsDataDeliveryTime {
  return 'deliveredTimestamp' in data
}

function isNpsScore(data: RawAnalyticsData): data is RawAnalyticsDataNpsScore {
  return 'score' in data
}

function isFirstMeasurement(data: RawAnalyticsData): data is RawAnalyticsDataFirstMeasurement {
  return 'firstMeasurementTimestamp' in data
}

function isSurvey(data: RawAnalyticsData): data is RawAnalyticsDataSurveyResponse {
  return 'questionType' in data
}

function getAnalyticsTypeLabel(type?: AnalyticsTypeEnum): string {
  if (type === AnalyticsTypeEnum.DevicesOrdered) return i18next.t('widgets.analytics.devicesOrdered')
  if (type === AnalyticsTypeEnum.NumberOfPatientsEnrolled)
    return i18next.t('widgets.analytics.numberOfPatientsEnrolled')
  if (type === AnalyticsTypeEnum.TotalDeviceCost) return i18next.t('widgets.analytics.totalDeviceCost')
  if (type === AnalyticsTypeEnum.NpsScore) return i18next.t('widgets.analytics.npsScore')
  if (type === AnalyticsTypeEnum.DeliveryTime) return i18next.t('widgets.analytics.deliveryTime')
  if (type === AnalyticsTypeEnum.FirstMeasurementTime) return i18next.t('widgets.analytics.firstMeasurementTime')
  if (type === AnalyticsTypeEnum.ParkviewSurveyOne) return i18next.t('widgets.analytics.parkviewSurvey1')
  if (type === AnalyticsTypeEnum.ParkviewSurveyTwo) return i18next.t('widgets.analytics.parkviewSurvey2')
  return ''
}

function getAnalyticsTypeDescription(type?: AnalyticsTypeEnum): string {
  if (type === AnalyticsTypeEnum.DevicesOrdered) return i18next.t('widgets.analytics.devicesOrderedDescription')
  if (type === AnalyticsTypeEnum.NumberOfPatientsEnrolled)
    return i18next.t('widgets.analytics.numberOfPatientsEnrolledDescription')
  if (type === AnalyticsTypeEnum.TotalDeviceCost) return i18next.t('widgets.analytics.totalDeviceCostDescription')
  if (type === AnalyticsTypeEnum.NpsScore) return i18next.t('widgets.analytics.npsScoreDescription')
  if (type === AnalyticsTypeEnum.DeliveryTime) return i18next.t('widgets.analytics.deliveryTimeDescription')
  if (type === AnalyticsTypeEnum.FirstMeasurementTime)
    return i18next.t('widgets.analytics.firstMeasurementTimeDescription')
  if (type === AnalyticsTypeEnum.ParkviewSurveyOne) return i18next.t('widgets.analytics.parkviewSurvey1Description')
  if (type === AnalyticsTypeEnum.ParkviewSurveyTwo) return i18next.t('widgets.analytics.parkviewSurvey2Description')

  return ''
}

export function getAggregationValue(data: RawAggregatedAnalytics['data'], aggregationType: 'sum' | 'average'): number {
  if (aggregationType === 'sum') {
    return data.reduce((acc, curr) => acc + curr.value, 0)
  }
  return data.reduce((acc, curr) => acc + curr.value, 0) / data.length
}

export function getDisplayValueWithUnit(value?: number, unit?: string): string {
  if (!value || Number.isNaN(value)) return '-'
  const roundedValue = Number.parseFloat(value.toFixed(1))
  if (unit === 'days') return i18next.t('widgets.analytics.days', { count: roundedValue })
  if (unit === '$') return `${unit}${roundedValue}`
  if (unit === 'answers') return i18next.t('widgets.analytics.answers', { count: roundedValue })
  if (unit) return `${roundedValue} ${unit}`
  return `${roundedValue}`
}

export const convertAggregatedAnalytics = (
  items: RawAggregatedAnalytics[],
  prevState?: GroupedAggregatedAnalytics,
): GroupedAggregatedAnalytics => {
  const groupedItems: GroupedAggregatedAnalytics = { ...prevState }

  items.forEach((item) => {
    const unit = item.data.length ? config[item.type].aggregationUnit : undefined
    const aggregatedValue = getAggregationValue(item.data, config[item.type].aggregationType)
    groupedItems[item.type] = {
      id: item.type,
      label: getAnalyticsTypeLabel(item.type),
      data: item.data,
      type: item.type,
      unit: item.data.length ? config[item.type].aggregationUnit : undefined,
      aggregationValue: getDisplayValueWithUnit(aggregatedValue, unit),
      chartType: config[item.type].chartType,
      description: getAnalyticsTypeDescription(item.type),
      color: config[item.type].color,
    }
  })
  return groupedItems
}

export const convertRawAnalytics = (rawData: RawAnalyticsData[], type: AnalyticsTypeEnum): RawAnalytics[] => {
  if (!rawData.length) return []
  return orderBy(
    rawData.map((item, index) => {
      const commonProps = {
        id: index,
        label: getAnalyticsTypeLabel(type),
        timestamp: item.timestamp,
        program: item.program,
        userId: item.userId,
        unit: config[type].rawUnit,
        clientIdentifier: item.clientIdentifier,
      }
      if (isFirstMeasurement(item)) {
        return {
          ...commonProps,
          deliveredTimestamp: item.deliveredTimestamp,
          firstMeasurementTimestamp: item.firstMeasurementTimestamp,
          daysToFirstMeasurement: item.daysToFirstMeasurement,
        }
      }
      if (isDevicesOrdered(item)) {
        return {
          ...commonProps,
          orderType: item.orderType,
          orderPrice: item.orderPrice,
        }
      }
      if (isDeliveryTime(item)) {
        return {
          ...commonProps,
          deliveredTimestamp: item.deliveredTimestamp,
          daysToDelivered: item.daysToDelivered,
          carrier: item.carrier,
          trackingNumber: item.trackingNumber,
        }
      }
      if (isNpsScore(item)) {
        return {
          ...commonProps,
          score: item.score,
        }
      }
      if (isSurvey(item)) {
        return {
          ...commonProps,
          questionType: item.questionType,
          question: item.question,
          response: item.responseValue,
        }
      }
      return { ...commonProps }
    }),
    'timestamp',
    'desc',
  )
}
