import { AnalyticsTypeEnum, QuestionTypeEnum } from '@vetahealth/tuna-can-api'

export interface AggregationData {
  value: number
  count: number
  timestamp: string
  unit?: string
}

export type AggregatedAnalytics = {
  id: string
  label: string
  data: AggregationData[]
  type: AnalyticsTypeEnum
  aggregationValue: string
  color: string
  unit?: string
  chartType?: string
  description?: string
}

export interface RawAnalyticsCommons {
  id: number
  userId: string
  label: string
  program: string
  timestamp: string
  unit?: string
  clientIdentifier?: string
}

interface DeviceOrder extends RawAnalyticsCommons {
  orderType: string
  orderPrice: number
}
interface DeliveryTime extends RawAnalyticsCommons {
  deliveredTimestamp: string
  daysToDelivered: number
  carrier: string
  trackingNumber: string
}

interface NpsScore extends RawAnalyticsCommons {
  score: number
}

interface FirstMeasurement extends RawAnalyticsCommons {
  firstMeasurementTimestamp: string
  daysToFirstMeasurement: number
}
export interface SurveyResponse extends RawAnalyticsCommons {
  response: string
  question: string
  questionType: QuestionTypeEnum
}

export type RawAnalytics =
  | RawAnalyticsCommons
  | DeviceOrder
  | DeliveryTime
  | NpsScore
  | FirstMeasurement
  | SurveyResponse

export enum ChartAggregationLevel {
  day = 'day',
  month = 'month',
  year = 'year',
}

export type GroupedAggregatedAnalytics = {
  [key in AnalyticsTypeEnum]?: AggregatedAnalytics
}

export type AppState = {
  aggregatedAnalytics?: GroupedAggregatedAnalytics
  rawAnalytics: RawAnalytics[]
  getAggregatedAnalytics: (
    site: string,
    types: AnalyticsTypeEnum[],
    programs: number[],
    aggregateBy: ChartAggregationLevel,
    start: string,
    end: string,
  ) => Promise<void>
  getRawAnalytics: (
    site: string,
    type: AnalyticsTypeEnum,
    programs: number[],
    start: string,
    end: string,
  ) => Promise<void>
  resetAnalytics: () => void
}
